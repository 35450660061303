<template>
  <div>
    <div class="canvasBox">
      <div class="vertical" id="ifra" :class="{vertical1:customer==='qingtian'}">
        <iframe
          ref="iframe"
          width="100%"
          height="80%"
          scrolling="no"
          frameborder="0"
          id="iframe"
          src="./2d/outRoof_VerticalView.html"
        ></iframe>
        <!-- 晴天tab -->
        <div class="tabs" v-if="customer==='qingtian'">
          <span :class="{spanact:activeName=='a'}" @click="activeName='a'" class="span1">俯视图</span>
          <span :class="{spanact:activeName=='b'}" @click="activeName='b'" >侧视图</span>
          <span :class="{spanact:activeName=='c'}" @click="activeName='c'">前视图</span>
          <span :class="{spanact:activeName=='d'}" @click="activeName='d'" class="span2">后视图</span>
        </div>
        <div class="btnbox">
          <div class="info" @click="infoEvent">基本信息</div>
          <div
            class="shelter"
            v-if="activeName == 'a'"
            @click.stop="shelterTypeShowEvent"
          >
            遮挡信息
          </div>
          <div
            class="door"
            v-if="activeName == 'c' || activeName == 'd'"
            @click.stop="shelterTypeShowEvent"
          >
            门窗信息
          </div>
          <ul
            class="options"
            v-show="shelterTypeShow && activeName == 'a'"
          >
            <li
              v-for="(item, index) in shelterInfoType"
              :key="index"
              @click="chooseShelterType(item)"
            >
              {{ item }}
            </li>
          </ul>
          <ul
            class="options options1"
            v-show="shelterTypeShow &&(activeName == 'c'||activeName == 'd') "
          >
            <li
              v-for="(item, index) in doorWindowType"
              :key="index"
              @click="chooseShelterType(item)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 锦浪tab -->
      <!-- eslint-disable vue/valid-v-model -->
      <van-tabs
        v-if="customer==='jinlang'"
        v-model:active="activeName"
        background="rgba(0,0,0,0)"
        title-inactive-color="#696C74"
        line-height="4px"
        line-width="25%"
        color="#04CC66"
        title-active-color="#04CC66"
      >
        <van-tab title="俯视图" name="a"> </van-tab>
        <van-tab title="侧视图" name="b">
          <!-- <ul class="options options2" v-if="SideViewShow">
            <li @click="chooseSideView('侧视图1')">侧视图1</li>
            <li @click="chooseSideView('侧视图2')">侧视图2</li>
          </ul> -->
        </van-tab>
        <van-tab title="前视图" name="c"> </van-tab>
        <van-tab title="后视图" name="d"> </van-tab>
      </van-tabs>
    </div>

    <div class="previewBtn" @click="previewEvent">预览</div>
    <van-popup v-model:show="show">
      <BaseInfo @popupClose="getPopupClose" v-model:baseInfoShow.sync = 'show'/>
    </van-popup>
    <van-popup v-model:show="shelterShow">
      <Parapet
        @popupClose="getPopupClose"
        v-show="shelterComShow == '女儿墙'"
      />
      <PlatformParapet
        @popupClose="getPopupClose"
        v-show="shelterComShow == '平台女儿墙'"
      />
      <Chimney
        @popupClose="getPopupClose"
        v-show="shelterComShow == '烟囱'"
      />
      <Heater
        @popupClose="getPopupClose"
        v-show="shelterComShow == '热水器'"
      />
      <RoofObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋面障碍物'"
      />
      <OutdoorObstacles
        @popupClose="getPopupClose"
        v-show="shelterComShow == '屋外障碍物'"
      />
      <NeighborHouse
        @popupClose="getPopupClose"
        v-show="shelterComShow == '邻居房屋'"
      />
      <TelegraphPole
        @popupClose="getPopupClose"
        v-show="shelterComShow == '电线杆'"
      />
      <Wire
        @popupClose="getPopupClose"
        v-show="shelterComShow == '电线'"
      />
      <Tree @popupClose="getPopupClose" v-show="shelterComShow == '树'" />
      <SunRoom
        @popupClose="getPopupClose"
        v-show="shelterComShow == '阳光房'"
      />
      <Billboard
        @popupClose="getPopupClose"
        v-show="shelterComShow == '广告牌'"
      />
      <Door @popupClose="getPopupClose" v-show="shelterComShow == '门'" />
      <Window
        @popupClose="getPopupClose"
        v-show="shelterComShow == '窗'"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  toRaw,
  getCurrentInstance,
  toRefs
} from 'vue'
import FarmerInfo from './../../components/FarmerInfo'
import BaseInfo from './BaseInfo'
import Parapet from '@/components/obstacles/Parapet'
import PlatformParapet from '@/components/obstacles/PlatformParapet'
import Chimney from '@/components/obstacles/Chimney'
import Heater from '@/components/obstacles/Heater'
import RoofObstacles from '@/components/obstacles/RoofObstacles'
import OutdoorObstacles from '@/components/obstacles/OutdoorObstacles'
import NeighborHouse from '@/components/obstacles/NeighborHouse'
import TelegraphPole from '@/components/obstacles/TelegraphPole'
import Wire from '@/components/obstacles/Wire'
import Tree from '@/components/obstacles/Tree'
import Billboard from '@/components/obstacles/Billboard'
import SunRoom from '@/components/obstacles/SunRoom.vue'
import Door from '@/components/obstacles/Door'
import Window from '@/components/obstacles/Window'
import emitter from '../../plugins/eventBus'

import { useRouter } from 'vue-router'
import {Dialog} from "vant";

export default {
  name: "Outerbuilding",
  components: {
    FarmerInfo,
    BaseInfo,
    Parapet,
    PlatformParapet,
    Chimney,
    Heater,
    RoofObstacles,
    OutdoorObstacles,
    NeighborHouse,
    TelegraphPole,
    Wire,
    Tree,
    SunRoom,
    Billboard,
    Door,
    Window
  },
  setup() {
    const iframe = ref(null)
    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const router = useRouter()
    const data = reactive({
      customer: window.customer,
      activeName: 'a',
      // SideViewNum:'侧视图',
      shelterTypeShow: false,
      shelterInfoType: [
        '女儿墙',
        '平台女儿墙',
        '烟囱',
        '热水器',
        '屋面障碍物',
        '邻居房屋',
        '阳光房',
        '电线杆',
        '电线',
        '树',
        '广告牌',
        '屋外障碍物'
      ],
      doorWindowType: ['门', '窗'],
      show: false, // 基本信息的显示隐藏
      shelterShow: false,
      shelterComShow: false,
      iframeWin: null,
      spanClick: 'handleObstacleClick',
      globleBarrier: {}
    })
    window[data.spanClick] = (params) => {
      methodsMap.editObstacle(params)
    }
    const SideViewShow = ref(false)

    router.afterEach((to, from, next) => {
      if (from.path === '/outerbuildingPreview') {
        console.log('我从outerbuildingPreview页面回来了------>')
        // 刷新页面方法
        location.reload()
      }
    })

    onMounted(() => {
      nextTick(() => {
        data.iframeWin = iframe.value.contentWindow
      })
    })

    const methodsMap = {
      editObstacle(params) {
        console.log(params, '编辑障碍物*****')
        data.shelterShow = true
        data.shelterComShow = true
        if (params.typeEnum === 1) {
          console.log(params, '女儿墙信息编辑')
          data.shelterComShow = '女儿墙'
          setTimeout(() => {
            emitter.emit('editPapert', params)
            emitter.emit('editstatePapert', 1)
          }, 500)
        }
        if (params.typeEnum === 2 || params.typeEnum === 3) {
          data.shelterComShow = '烟囱'
          setTimeout(() => {
            emitter.emit('editRectChimney', params)
            emitter.emit('editstateChimney', 1)
          }, 500)
        }
        if (params.typeEnum === 4) {
          data.shelterComShow = '热水器'
          setTimeout(() => {
            emitter.emit('editHotWater', params)
            emitter.emit('editstate', 1)
          }, 500)
        }
        if (params.typeEnum === 5) {
          data.shelterComShow = '屋面障碍物'
          setTimeout(() => {
            emitter.emit('editRoomObstacle', params)
            emitter.emit('editstateRoomObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 6) {
          data.shelterComShow = '电线杆'
          setTimeout(() => {
            emitter.emit('editRoomOutObstaclePoleLine', params)
            emitter.emit('editstateRoomOutObstaclePoleLine', 1)
          }, 500)
        }
        if (params.typeEnum === 7) {
          data.shelterComShow = '树'
          setTimeout(() => {
            emitter.emit('editTree', params)
            emitter.emit('editstateTree', 1)
          }, 500)
        }
        if (params.typeEnum === 8) {
          data.shelterComShow = '屋外障碍物'
          setTimeout(() => {
            emitter.emit('editRoomOutObstacle', params)
            emitter.emit('editstateRoomOutObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 9) {
          data.shelterComShow = '广告牌'
          setTimeout(() => {
            emitter.emit('editRoomOutObstacleAds', params)
            emitter.emit('editstateRoomOutObstacleAds', 1)
          }, 500)
        }
        if (params.typeEnum === 10) {
          console.log(params, '触发了上人通道')
          data.shelterComShow = '上人通道'
          setTimeout(() => {
            emitter.emit('editChannel', params)
            emitter.emit('editstateChannel', 1)
          }, 500)
        }
        if (params.typeEnum === 11) {
          console.log(params, '555555邻居反诬')
          data.shelterComShow = '邻居房屋'
          setTimeout(() => {
            emitter.emit('editNeiborRoom', params)
            emitter.emit('editstateNeiborRoom', 1)
          }, 500)
        }
        if (params.typeEnum === 12) {
          data.shelterComShow = '电线'
          setTimeout(() => {
            emitter.emit('editWireObstacle', params)
            emitter.emit('editstateWireObstacle', 1)
          }, 500)
        }
        if (params.typeEnum === 13) {
          data.shelterComShow = '阳光房'
          setTimeout(() => {
            emitter.emit('editSunHouse', params)
            emitter.emit('editstateSunHouse', 1)
          }, 500)
        }
      },
      sendMsgTo2D(v) {
        console.log(v, '需要传递的数据')
        setTimeout(() => {
          data.iframeWin.postMessage(v, "*");
        }, 1000)
      },
      goBack() {
        router.go(-1)
      },
      previewEvent() {
        router.push('/outerbuildingPreview')
      },
      infoEvent() {
        data.show = true
      },
      shelterTypeShowEvent() {
        data.shelterTypeShow = true
      },
      chooseShelterType(item) {
        data.shelterComShow = item
        data.shelterTypeShow = false
        data.shelterShow = true
      },

    // function clickTab() {
    //   if (data.activeName == 'b') {
    //     SideViewShow.value = true
    //   }else{
    //   data.SideViewNum='侧视图'
    //   }
    // }
    // function chooseSideView(item) {
    //   SideViewShow.value = false
    //   data.SideViewNum=item
    // }

      getPopupClose(value) {
        emitter.emit('editstate', 2)
        console.log(value, '页面打开总是获取信息')
        methodsMap.sendMsgTo2D(toRaw(value))
        data.popShow = false
        data.shelterShow = false
        setTimeout(() => {
          if (sessionStorage.getItem('hit') !== null) {
            Dialog.alert({
              message: sessionStorage.getItem('hit')
            }).then(() => {
              sessionStorage.removeItem('hit')
            })
          }
        }, 2000)
      }
    }

    return {
      ...toRefs(data),
      // shelterTypeShowEvent,
      // chooseShelterType,
      // infoEvent,
      // getPopupClose,
      iframe,
      ...methodsMap,
      // chooseSideView,
      // SideViewShow,
      // clickTab
      // viewTypeEvent
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/style/jinlang/outerbuilding.scss";
@import "@/style/qingtian/outerbuilding.scss";

#iframe {
  position: absolute;
  width: 100vw;
  top: -40%;
  height: 740px !important;
}
#ifra {
  height: 12rem;
  overflow: hidden;
}

</style>
